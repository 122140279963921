import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import KeywordAddForm from "./KeywordAddForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PreferredList from "./PreferredList";
import Loader from "../CommonLoader";
import CampaignSettingsList from "./CampaingSettingList";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BrandMentions from "./BrandMentions";
import { Helmet } from 'react-helmet';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { ToastContainer, toast } from "react-toastify";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";

export default function KeywordsList() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpens, setIsOpens] = React.useState(false);
  const [modalIsOpenAdd, setIsOpenAdd] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [EditBrandTerm, SetEditBrandTerm] = useState('')
  const [EditBrandMatch, SetEditBrandMatch] = useState('')
  const [EditBrandId, SetEditBrandId] = useState('')

  const [EnableKeywordAddForm, SetEnableKeywordAddForm] = useState(false)

  const [CallPreferredList, SetCallPreferredList] = useState(false)
  const [buttnval, setButtnval] = useState("1")
  const [menubutval, setMenubutval] = useState("1")
  const [pagename, setPagename] = useState("Targeted Keywords")
  const [deleteId, SetDeleteId] = useState("")
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [editErr, seteditErr] = useState();
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap')) {
      setfaqData(2)
      setToggle(false)
    }
  });
  const menuChange = e => {
    e.preventDefault();
    setfaqData(2)
    setToggle(false)
    faqdatafun(e.target.id)
    setMenubutval(e.target.id)
    setButtnval(e.target.id)
    if (e.target.id == 1)
      setPagename("Targeted Keywords")
    else if (e.target.id == 2)
      setPagename("Branded Terms")
    else if (e.target.id == 3)
      setPagename("Campaigns")
    else if (e.target.id == 4)
      setPagename("Brand Mentions")

    if (e.target.id == 2) {
      callUserdataApi()
    }

  };


  const [selectedRows, setSelectedRows] = useState([]);
  const [initialDateval, SetInitialDateval] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const navigate = useNavigate();


  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  // const gradientStops = [
  //   {
  //     key: "0%",
  //     stopColor: color(fillColor).darker(0.5).toString(),
  //     stopOpacity: 1,
  //     offset: "0%",
  //   },
  //   {
  //     key: "50%",
  //     stopColor: fillColor,
  //     stopOpacity: 0.75,
  //     offset: "50%",
  //   },
  //   {
  //     key: "100%",
  //     stopColor: color(fillColor).brighter(0.5).toString(),
  //     stopOpacity: 0.5,
  //     offset: "100%",
  //   },
  // ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpens = async (e) => {
    SetDeleteId(e.target.id)
    setIsOpens(true);
  };
  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setIsOpens(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });


  useEffect(() => {
    callUserdataApi();
    faqdatafun();

  }, []);
  const faqdatafun = async (menubutval = "") => {
    if (menubutval == 1 || menubutval == "")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=1`
    else if (menubutval == 2)
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=2`
    else if (menubutval == 3)
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=3`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "brand_term",
      headerName: "Brand Term Name",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: 'A brand name was added to the campaign.',

      disableClickEventBubbling: true,
    },
    {
      field: "match",
      headerName: "Match Type",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: 'A match type was added for the brand term name.',
      disableClickEventBubbling: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <Tooltip title={'Click here to edit the brand'}>
            <a className="EditBrandedTermClass" href="javascript:void(0)" data-id={cardData.row.id}>
              <button className="buttonView edit_icon" onClick={EditBrandedTerm}>

                <EditIcon />
              </button>
            </a>
          </Tooltip>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <Tooltip title={'Click here to delete the brand'}>
            <a className="DeleteBrandedtermClass" href="javascript:void(0)" data-id={cardData.row.id}>
              <button className="buttonView trash_icon"
                //  onClick={handleClickOpens}  
                onClick={() => handleDeleteClick(cardData.row.id)}
                id={cardData.row.id}>

                <DeleteIcon />
              </button>
            </a>
          </Tooltip>
        );
      },
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/brandterm_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.result && datavalue?.data?.result?.length > 0) {
        if (datavalue?.data?.result) {
          const newData = datavalue?.data?.result.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
        }
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }

    } catch (err) {
      console.log(err)
    }



    setLoading(false);





  };
  const campaignreportNow = async () => {
    if (selectedRows.length > 0) {

      setdateIsOpen(true)
    }
    else {
      setdateIsOpen(true)
    }
  }
  function datecloseModal() {
    setdateIsOpen(false)
  }
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    seteditErr("")
    setIsOpen(false);
    setIsOpens(false);
    SetEditBrandErr('')
  }
  function openModalAdd() {
    setIsOpenAdd(true);
  }
  function closeModalAdd() {
    seteditErr("")
    setIsOpenAdd(false);
    SetErrorsAdd([])
    setSelectCampaign([])
  }
  function addbrands() {
    navigate("/add-brands")
  }
  const handleSelectionModelChange = (newSelection) => {


    setSelectionModel(newSelection);


  };
  const handleConfirmDelete = () => {
    DeleteBrandedterm(deleteId);
  };

  const DeleteBrandedterm = async (deleteId) => {
    // const Id = e.target.closest(".DeleteBrandedtermClass").getAttribute("data-id");
    setIsOpens(false);
    const Id = deleteId
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/brandterm_delete/?user_domain_id=${esodata.domaindata.value.header_selected}&brand_id=${Id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

    } catch (err) {
      console.log(err)
    }


    callUserdataApi()



  }
  const [EditCampaignName, SetEditCampaignName] = useState('')
  const EditBrandedTerm = (e) => {

    SetEditBrandTerm('')
    SetEditBrandMatch('')
    SetEditCampaignName('')

    const Id = e.target.closest(".EditBrandedTermClass").getAttribute("data-id");

    const foundObject = findObjectById(cardData, parseInt(Id));
    SetEditBrandId(parseInt(Id))

    if (foundObject) {
      SetEditBrandTerm(foundObject.brand_term)
      SetEditBrandMatch(foundObject.match)
      SetEditCampaignName(foundObject.category__name)
    }


    setIsOpen(true)



  }

  const [EditBrandErr, SetEditBrandErr] = useState('')

  const SubmitEditBrand = async () => {

    var Err = 0
    SetEditBrandErr('')
    if (EditBrandTerm === '') {

      Err = 1;
      seteditErr("Enter the brand terms")
    }
    if (EditBrandMatch === '') {
      Err = 1;
    }

    if (Err === 0) {

      setLoading(true)


      try {

        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/brandterm_edit/?user_domain_id=${esodata.domaindata.value.header_selected}&brand_id=${EditBrandId}&brand_term=${EditBrandTerm}&match=${EditBrandMatch}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

        setIsOpen(false)
        setLoading(false)
        callUserdataApi()

      } catch (err) {
        setLoading(false)
        if (err?.response?.data?.error) {
          SetEditBrandErr(err.response.data.error)
        }
        console.log(err)
      }









    }

  }

  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };

  const [CallCampaignTable, SetCallCampaignTable] = useState(false)

  const IncreseBrandIndex = () => {
    const node = document.getElementsByClassName("branded_term")[0];
    var clone = node.cloneNode(true);

    var clonedInputs = clone.querySelectorAll("input");

    var clonedXButton = clone.querySelectorAll(".IncreseBrandIndexClone");
    var clonedInputs = clone.querySelectorAll("input");

    // clonedInputs.forEach(function (input) {
    //   if (input.type === "text" || input.type === "radio" || input.type === "checkbox") {
    //     input.value = ""; // Clear the input value
    //     if (input.type === "checkbox" || input.type === "radio") {
    //       input.checked = false; // Uncheck radio/checkbox
    //     }
    //   }
    // });

    clonedInputs.forEach(function (input) {
      if (input.type === "text") {
        input.value = ""; // Clear the text input value
      } else if (input.type === "radio" || input.type === "checkbox") {
        input.checked = false; // Uncheck radio/checkbox
      }
    });
    clonedXButton.forEach(function (button) {
      button.innerHTML = "";
      var xIcon = document.createElement("i");
      xIcon.className = "fa fa-times brandedtermremove"; // Add the appropriate class for the x icon
      button.appendChild(xIcon); // Add the x icon to the button
    });

    var parentDiv = document.getElementById("branded_term_clone");
    var elements = parentDiv.querySelectorAll("*");
    var numElements = elements.length;

    clonedInputs.forEach(function (input, index) {
      if (input.type === "radio") {
        input.setAttribute("name", "brand_match[" + (numElements + 1) + "]");
      }
      if (input.type === "text") {
        input.setAttribute(
          "name",
          "brand_match_text_" + (numElements + 1) + ""
        );
      }
    });

    document.getElementById("branded_term_clone").appendChild(clone);
  };

  // const IncreseBrandIndex = () => {
  //   const node = document.getElementsByClassName("branded_term")[0];
  //   var clone = node.cloneNode(true);

  //   // Clear the values of the cloned input fields


  //   // Handle the cloned X button
  //   var clonedXButton = clone.querySelectorAll(".IncreseBrandIndexClone");
  //   clonedXButton.forEach(function (button) {
  //     button.innerHTML = "";
  //     var xIcon = document.createElement("i");
  //     xIcon.className = "fa fa-times brandedtermremove"; // Add the appropriate class for the x icon
  //     button.appendChild(xIcon); // Add the x icon to the button
  //   });

  //   // Update the names of the cloned inputs
  //   var parentDiv = document.getElementById("branded_term_clone");
  //   var elements = parentDiv.querySelectorAll("*");
  //   var numElements = elements.length;

  //   clonedInputs.forEach(function (input, index) {
  //     if (input.type === "radio") {
  //       input.setAttribute("name", "brand_match[" + (numElements + 1) + "]");
  //     }
  //     if (input.type === "text") {
  //       input.setAttribute("name", "brand_match_text_" + (numElements + 1) + "");
  //     }
  //   });

  //   // Append the cloned node to the parent div
  //   document.getElementById("branded_term_clone").appendChild(clone);
  // };


  document.body.addEventListener("click", function (event) {
    if (event.target.classList.contains("brandedtermremove")) {
      // Find the closest ancestor element with the class 'branded_term' and remove it
      var brandedTerm = event.target.closest(".branded_term");
      if (brandedTerm) {
        brandedTerm.remove();
      }
    }
  });


  const [campaigndata, Setcampaigndata] = useState([])
  const [SelectCampaign, setSelectCampaign] = useState([])
  const GetCampaignData = async () => {


    Setcampaigndata([])

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,

        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (response?.data) {

        Setcampaigndata(response.data)
      }


    } catch (err) {
      console.log(err)
    }



  }
  const addKeywordform = () => {
    navigate('/add-keyword');
  }
  const regionalsetting = () => {
    navigate('/regional-level-settings');
  }
  const [ErrorsAdd, SetErrorsAdd] = useState([])

  const SaveBrand = async () => {

    SetErrorsAdd([])

    //branding term

    var BrandingTermNameArray = [];
    var BrandingTermMatchArray = [];
    var BrandingMergeArray = [];

    // Get all elements with the 'example' class
    var examples = document.querySelectorAll(".branded_term");

    // Loop through each 'example' element
    examples.forEach(function (example) {
      // Get all the input elements of type text within the current example
      var textInputs = example.querySelectorAll('input[type="text"]');

      // Loop through the retrieved elements and do something with them
      for (var i = 0; i < textInputs.length; i++) {
        BrandingTermNameArray.push(textInputs[i].value);

        // Do something with each input element
      }
    });

    // Get all elements with the 'example' class
    var examples = document.querySelectorAll(".branded_term");

    // Loop through each 'example' element
    examples.forEach(function (example) {
      // Get all the radio button elements within the current example
      var radioInputs = example.querySelectorAll('input[type="radio"]');

      // Loop through the retrieved elements and get the value of each checked input
      radioInputs.forEach(function (input) {
        if (input.checked) {
          BrandingTermMatchArray.push(input.value);

          // Do something with the value of each checked input
        }
      });
    });

    if (BrandingTermNameArray.length === BrandingTermMatchArray.length) {
      BrandingTermNameArray.forEach((element, index) => {
        BrandingMergeArray.push({
          term: BrandingTermNameArray[index],
          match: BrandingTermMatchArray[index],
        });
      });
    }

    console.log('innnnn', BrandingMergeArray)
    var Err = 0;
    // if(SelectCampaign)
    // {
    //   if(SelectCampaign[0])
    //   {
    //     if(SelectCampaign[0].campaign_id)
    //     {
    //       Err=0;
    //     }
    //     else{
    //       Err=1;
    //     }
    //   }
    //   else{
    //     Err=1;
    //   }
    // }

    var EArray = [];

    if (Err == 1) {

      EArray.push({
        message: 'Please select campaign name'
      })

    }

    if (BrandingMergeArray.length === 0) {
      Err = 2;
    }

    if (Err == 2) {
      EArray.push({
        message: 'Enter the brand terms and choose matching type'
      })
    }

    SetErrorsAdd(EArray)


    if (Err === 0) {

      try {

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/add_brandterm/`,
          {
            user_domain_id: `${esodata.domaindata.value.header_selected}`,
            // campaign_id:SelectCampaign[0].campaign_id,
            brand_terms: BrandingMergeArray
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

        if (response.data) {
          callUserdataApi()
          closeModalAdd()
        }

      } catch (err) {

        if (err.response) {
          if (err.response.data) {
            if (err.response.data.errors) {
              SetErrorsAdd(err.response.data.errors)
            }
          }
        }


        console.log(err)
      }

    }








  }

  useEffect(() => {
    GetCampaignData()
  }, [])
  const pdfdown = async () => {
    setLoading(true);
    try {
      if (pagename == "Targeted Keywords")
        var pdfname = `${process.env.REACT_APP_API_URL}keywords/v2/keyword_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf`;
      else if (pagename == "Branded Terms")
        var pdfname = `${process.env.REACT_APP_API_URL}keywords/v2/brandterm_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf`;
      else
        var pdfname = `${process.env.REACT_APP_API_URL}keywords/v2/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf`;
      const response = await fetch(
        pdfname,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        }
      );
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        if (pagename == "Targeted Keywords")
          var filedata = await Commonfun("Keyword", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        else if (pagename == "Branded Terms")
          var filedata = await Commonfun("Brandedterm", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        else
          var filedata = await Commonfun("Campaign", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        // link.download = 'AuditSummaryDetail.pdf';
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // console.error(
        //   "Failed to fetch PDF:",
        //   response.status,
        //   response.statusText
        // );
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
  };
  const csvdown = async () => {
    setLoading(true);
    try {
      if (pagename == "Targeted Keywords")
        var pdfname = `${process.env.REACT_APP_API_URL}keywords/v2/keyword_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv`;
      else if (pagename == "Branded Terms")
        var pdfname = `${process.env.REACT_APP_API_URL}keywords/v2/brandterm_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv`;
      else
        var pdfname = `${process.env.REACT_APP_API_URL}keywords/v2/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv`;
      const response = await fetch(
        pdfname,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        }
      );
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (pagename == "Targeted Keywords")
          var filedata = await Commonfun("Keyword", "csv", ApiToken, esodata.domaindata.value.header_selected);
        else if (pagename == "Branded Terms")
          var filedata = await Commonfun("Brandedterm", "csv", ApiToken, esodata.domaindata.value.header_selected);
        else
          var filedata = await Commonfun("Campaign", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // console.error(
        //   "Failed to fetch PDF:",
        //   response.status,
        //   response.statusText
        // );
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
  };

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Targeted Keywords</title>
      </Helmet>
      {/* {
      loading===true ?   <Loader/> : null
    } */}


      <div className="cnt_audit_outer_wrap">

        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">{pagename} </h2>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? "faq_move_class" : ""}`}>
              <button
                onClick={handleToggle}
                className="faq_class"
                title="Frequently Asked Question"
              >
                <QuestionMarkIcon />
              </button>
              {toggle && (
                <Keywordbar
                  close={() => setToggle(false)}
                  initialDateval={initialDateval}
                />
              )}
              <img
                src={pdficon}
                alt="pdf icon"
                title="PDF Download"
                onClick={pdfdown}
              />
              <img
                src={csvicon}
                alt="csv icon"
                title="CSV Download"
                onClick={csvdown}
              />
            </div>

          </div>
        </div>

        <div className="site_cnt_hdr_blw_wrap">
          <button
            type="button"
            id={1}
            className={`site_rn_rpt_btn text_font ${buttnval === "1" ? "active" : ""}`}
            onClick={(e) => menuChange(e)}
          >
            Keywords
          </button>
          <button
            type="button"
            id={2}
            className={`site_rn_rpt_btn text_font ${buttnval === "2" ? "active" : ""}`}
            onClick={(e) => menuChange(e)}
          >
            Branded Terms
          </button>

          <button
            type="button"
            id={3}
            className={`site_rn_rpt_btn text_font ${buttnval === "3" ? "active" : ""}`}
            onClick={(e) => menuChange(e)}
          >
            Campaigns
          </button>
        </div>

        {menubutval === "1" ? (
          <>

            <div className="cnt_hdr_blw_wrap add_keyword_button_wrap regionalsetting">
              <button
                className="run_rpt_btn rn_rpt_btn"
                onClick={addKeywordform}
              >
                Add Keywords
              </button>

              <button
                className="run_rpt_btn rn_rpt_btn "
                onClick={regionalsetting}
              >
                Regional Level Settings
              </button>
            </div>
            {EnableKeywordAddForm === true ? (
              <div className="keyword_add_form">
                <KeywordAddForm
                  SetEnableKeywordAddForm={SetEnableKeywordAddForm}
                  CallCampaignTable={CallCampaignTable}
                  SetCallCampaignTable={SetCallCampaignTable}
                  SetCallPreferredList={SetCallPreferredList}
                />
              </div>
            ) : null}

            <PreferredList
              CallPreferredList={CallPreferredList}
              SetCallPreferredList={SetCallPreferredList}
            />
          </>
        ) : null}

        {menubutval === "2" ? (
          <>

            <div className="cnt_hdr_blw_wrap add_keyword_button_wrap">
              <button
                className="run_rpt_btn rn_rpt_btn"
                onClick={() => {
                  openModalAdd();
                }}
              >
                Add Brands
              </button>
            </div>
            <div className="tgbranded_term_heading"></div>
            <div
              className="data_table brand_list_table "
              style={{ width: "100%", height: "480px" }}
            >

              <DataGrid
                getRowId={(row) => row.sid}
                slots={{
                  noRowsOverlay: MyCustomNoRowsOverlay,
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                rows={rows}
                columns={columns}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                  {
                    py: "22px",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  // ".MuiDataGrid-virtualScroller": {
                  //   height: "auto !important",
                  //   overflowY: "auto",
                  // },
                  "& .MuiDataGrid-cell:first-child": {
                    position: "unset",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnHeader:first-child": {
                    position: "sticky",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    border: "none",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnSeparator--sideRight": {
                    display: "none"
                  },
                  
                }}
                getRowHeight={() => "auto"}
                autoHeight={autoheightval ? true : false}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                // loading={loading}
                pageSizeOptions={[10, 30, 50, 75, 100]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            </div>
          </>
        ) : null}

        {menubutval === "3" ? (

          <CampaignSettingsList
            EnableKeywordAddForm={EnableKeywordAddForm}
            SetEnableKeywordAddForm={SetEnableKeywordAddForm}
            CallCampaignTable={CallCampaignTable}
            SetCallCampaignTable={SetCallCampaignTable}
            GetCampaignData={GetCampaignData}
          />
        ) : null}

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter keyword_edit_form"
        >
          <DialogTitle>{"Edit Brand Term"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div>
                  <div className="">
                    <div className="keyword_edit_form_row">
                      <span>Brand Term Name: </span>
                      <input
                        type="text"
                        defaultValue={EditBrandTerm}
                        onChange={(e) => {
                          SetEditBrandTerm(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="errs" style={{ color: "red" }}>{editErr}</div>
                  <div className="keyword_edit_form_match_row">
                    <span>Match Type: </span>
                    <input
                      type="radio"
                      name="match_type"
                      value={"phrase"}
                      defaultChecked={
                        EditBrandMatch === "phrase" ? true : false
                      }
                      onChange={(e) => {
                        SetEditBrandMatch(e.target.value);
                      }}
                    />
                    Phrase Match
                    <input
                      type="radio"
                      name="match_type"
                      value={"exact"}
                      defaultChecked={EditBrandMatch === "exact" ? true : false}
                      onChange={(e) => {
                        SetEditBrandMatch(e.target.value);
                      }}
                    />
                    Exact Match
                    {/* <input type="text" defaultValue={EditBrandMatch} onChange={(e)=>{SetEditBrandMatch(e.target.value)}}/> */}
                  </div>
                </div>
              </div>
              <div className="err">{EditBrandErr}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="run_rpt_btn rn_rpt_btn"
              onClick={SubmitEditBrand}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={modalIsOpenAdd}
          onClose={closeModalAdd}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter keyword_edit_form brand_term_add_form"
        >
          <DialogTitle>{"Add Brand Term"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                {/* 
            <div className="eighty_per"> 
          <label className="form-label">Campaign:</label>
          <Select
          options={campaigndata}
                    labelField="campaign_name"
                    valueField="campaign_id"
                    onChange={(values) => setSelectCampaign(values)}
           />
        </div>
       */}
                <div className="form-inner_brands">
                  <div className="branded_term">
                    <div className="row addbranded_terms">
                      <div className="colhead brandhead">
                        <span className="col12">Branded Term :</span>
                        <div className="col21">
                          <input
                            name="brand_match_text_0"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="colhead inputhead">
                        <input
                          type="radio"
                          name="brand_match[]"
                          value={"phrase"}
                        />
                        Phrase Match
                        <input
                          type="radio"
                          name="brand_match[]"
                          value={"exact"}
                        />
                        Exact Match
                        <span
                          className="IncreseBrandIndexClone BrandMatchPlus"
                          onClick={IncreseBrandIndex}
                        >
                          <AddIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="branded_term_clone"></div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          {ErrorsAdd.map((item) => (
            <div className="err" style={{ marginLeft: "20px" }}>{item.message}</div>
          ))}

          <DialogActions>
            <button
              className="run_rpt_btn rn_rpt_btn"
              style={{ cursor: "pointer" }}
              onClick={SaveBrand}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModalAdd}>
              close
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={modalIsOpens}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter"
        >
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                {/* Are You Sure Delete the Brand Term Name? */}
                Are you sure you want to delete the Brand Term Name?
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="run_rpt_btn rn_rpt_btn"
              onClick={handleConfirmDelete}
              style={{ cursor: "pointer" }}
            >
              OK
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
