import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";

import { Link, useParams } from "react-router-dom";

import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import errorimages from "../Asset/download1.png"
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { Bar } from "react-chartjs-2";
import { AppContext } from "../../../Context/Context";
export default function AccessibilitySummary() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [jobidValue, setjobValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [selectedValues, setSelectedValues] = useState();
  const [jobselectedValues, setJobselectedValues] = useState([]);
  const [responsedata, SetResponsedata] = useState([]);
  const [columndata, Setcolumndata] = useState([])
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [responsetypedata, SetResponsetypedata] = useState([]);
  const [responsevaldata, SetResponsevaldata] = useState([])

  const navigate = useNavigate();

  const ApiToken = esodata.apiauthkey.value
  let result = ApiToken.split(" ");

  let detailurl = `${process.env.REACT_APP_DOMAIN}accessibility-report-detail/`;



  const apihandleClose = () => {
    setapiOpen(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );



  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=56`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  useEffect(() => {
    callUserdataApi();
    // LoadAddFormFilterOptions();
    GetFilterOptions();
    faqdatafun()
    const findVirtualScroller = () => {
      const virtualScrollerElement = document.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      if (!virtualScrollerElement) {
        setTimeout(findVirtualScroller, 100);
      } else {
        virtualScrollerElement.addEventListener(
          "scroll",
          handleScrollHorizontal
        );
        return () => {
          virtualScrollerElement.removeEventListener(
            "scroll",
            handleScrollHorizontal
          );
        };
      }
    };
    findVirtualScroller();
  }, []);
  const handleScrollHorizontal = (() => {
    const currentScrollPos = document.querySelector(
      ".MuiDataGrid-virtualScroller"
    ).scrollLeft;
    const columnsHeaders = document.querySelectorAll(
      // ".MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:first-child)"
      ".MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:nth-child(-n+3))"
    );
    columnsHeaders.forEach((columnHeader) => {
      columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
    });
  })


  const rows = cardData ?? [];
  const callUserdataApi = async (jobidval) => {
    if (typeof jobidval !== 'undefined')
      var testarrval = [jobidval];
    else
      var testarrval = [];

    setLoading(true);
    setCardData([])
    var passpostapi = {
      report_id: "8",
      user_domain_id: esodata.domaindata.value.header_selected,
      column_ids: [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
      job_ids: testarrval,
      type: "accessibility_summary",
    };
    try {
      const responsedatavalue = await axios
        .post(
          `${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`,
          passpostapi,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate("/errorpages");
          }
          return false;
        });
        if(responsedatavalue?.data?.job_date)
        setjobDate(moment(responsedatavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))    
        else
        setjobDate(moment(responsedatavalue?.data?.job_date).format("MMMM DD,YYYY")) 
      
      if (responsedatavalue?.data) {
       
        SetResponsedata(responsedatavalue?.data);
        setCardData(responsedatavalue?.data?.table_data)
        setLoading(false);
        setIsCardVisible(false)
        var ColumnsMerge = [];
        const columnsIni = [
          {
            field: "id",
            headerName: "#",
            minWidth: 200,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "type",
            headerName: "Type",
            minWidth: 550,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
          },
        ]

        responsedatavalue?.data?.table_data.forEach(element => {
          ColumnsMerge.push({

            field: element.job__completed_date,
            headerName: "Value",
            minWidth: 570,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
          })
        })
        ColumnsMerge = columnsIni.concat(ColumnsMerge)

        Setcolumndata(ColumnsMerge)
        var LoadTableValue = [];
        var responserdatatype = [];
        responsedatavalue?.data?.row_names.map((element, i) => {
          responserdatatype.push(element)
          LoadTableValue.push({
            id: i + 1,
            type: element

          })
        })
        SetResponsetypedata(responserdatatype)
        var responservaldatatype = [];
        LoadTableValue.map((elements, i) => {
          responsedatavalue?.data?.table_data.map((element, j) => {

            if (responsedatavalue?.data?.table_data[j] !== undefined) {
              responservaldatatype.push(responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]])
              LoadTableValue[i][element.job__completed_date] = responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]]
            }
            else
              LoadTableValue[i][element.job__completed_date] = "-"
          })
        })
        SetResponsevaldata(responservaldatatype)
        setCardData(LoadTableValue)
      }
    } catch (err) { }


    setLoading(false);
  };

  const reportNow = async () => {

    var errs = 1;
    let errors = {};

    if (dEngineoption.id == "") {
      errors.dateerror = "All Fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }

    closeModal();
    setLoading(true);
    setJobselectedValues(dEngineoption.id)
    callUserdataApi(dEngineoption.id)

  };
  function openModal() {
    setfaqData(2)
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);



  }

  const [WcagType, SetWcagType] = useState([])


  const GetFilterOptions = async () => {
    setcompletedate([])
    try {
      const FetchList = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=accessibility2`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (FetchList.data) {
        if (FetchList?.data?.jobs.length > 0) {

          SetdEngineoption(FetchList.data.jobs[0])
          setcompletedate(FetchList.data.jobs)
        }
      }



    } catch (err) {
      console.log('err', err)
    }

  }

  const OnsetSelectval = (values) => {
    setSelectval(values[0].id)

  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('accessibility2')

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });
  const pdfdown = async () => {
    setLoading(true);
    console.log("passpostapis", passpostapis)
    try {
      var passpostapis = {
        report_id: "8",
        user_domain_id: esodata.domaindata.value.header_selected,
        column_ids: [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
        job_ids: jobselectedValues,
        type: "accessibility_summary",
        is_format: "pdf",
        timezone:timezoneval()
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${ApiToken}`,
        },
        body: JSON.stringify(passpostapis)

      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("AccessiblitySummary", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {
      var passpostapis = {
        report_id: "8",
        user_domain_id: esodata.domaindata.value.header_selected,
        column_ids: [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
        job_ids: jobselectedValues,
        type: "accessibility_summary",
        is_format: "csv",
        timezone:timezoneval()
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${ApiToken}`,
        },
        body: JSON.stringify(passpostapis)

      });
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessiblitySummary", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  }

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });

  const preprocessData = (data) => {
    var percentage = (Math.min(...data) / Math.max(...data)) * 100;
    if(percentage < 1) {
      return data.map((value) => {
        var valuePercentage = (value / Math.max(...data)) * 100;
        if (valuePercentage > 0 && valuePercentage < 1) {
          var onePercent = (1 / 100) * Math.max(...data);
          return onePercent; // Ensure minimum bar height for small values
        }
        return value;
      });
    }else {
      return data;
    }
  };

  const barChartData = {
    labels: responsetypedata,
    datasets: [
      {
        label: "Type",
        data: preprocessData(responsevaldata),
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: "#FE6020",
      },
    ],
  };
  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Accessibility Summary</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Accessibility Summary</h2>
            {/* <p>as of {jobDate}</p> */}
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
              <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                <QuestionMarkIcon />

              </button>
              {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
              <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
              <img src={csvicon} alt="csv icon" onClick={csvdown} />
            </div>

          </div>
        </div>
        <div className="link_cnt_hdr_blw_wrap">
          <Link to="/accessibility-score" ><button type="button" className={`site_rn_rpt_btn`}  >Score</button></Link>
          <Link to="/accessibility-summary" ><button type="button" className={`site_rn_rpt_btn active`}  >Summary</button></Link>
          <Link to="/accessibility-report" ><button type="button" className={`site_rn_rpt_btn`}  >Report</button></Link>
          <Link to="/accessibility-file" ><button type="button" className={`site_rn_rpt_btn `}  >File Accessibility</button></Link>
        </div>

        <div className="cnt_hdr_blw_wrap">
          <button className="run_rpt_btn rn_rpt_btn" onClick={() => { SetRunReportPopShow(true) }}>
            Run Report Now
          </button>
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" className="zoom" />
            </span>
          </button>

        </div>
        <div className="site_Chart_info_inner">
          {/* <Bar
            type="bar"
            width={130}
            height={150}
            data={barChartData}
            options={{
             // responsive: true,
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      // Display the original value in the tooltip
                      return `Value: ${responsevaldata[context.dataIndex]}`;
                    },
                  },
                },
                // 'legend' now within object 'plugins {}'
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: "Accessibility Summary",
                  color: "#0b2033",
                  font: {
                    size: 18,
                    family: 'Poppins',
                  },
                },
              },
              scales: {
                y: {
                  // not 'yAxes: [{' anymore (not an array anymore)
                  ticks: {
                    suggestedMin: 0,
                    stacked: true,
                    color: "#0b2033", // not 'fontColor:' anymore
                    // fontSize: 18,
                    font: {
                      size: 14, // 'size' now within object 'font {}'
                      family: 'Poppins',
                    },
                    beginAtZero: true,
                  },
                },
                x: {
                  // not 'xAxes: [{' anymore (not an array anymore)
                  ticks: {
                    color: "#0b2033", // not 'fontColor:' anymore
                    //fontSize: 14,
                    font: {
                      size: 12, // 'size' now within object 'font {}'
                      family: 'Poppins',
                    },
                    beginAtZero: true,
                  },
                },
              },
            }}
          /> */}
          <Bar
  type="bar"
  width={1200} // Set the width of the canvas
  height={450} // Set the fixed height of the canvas
  data={barChartData}
  options={{
    responsive: false, // Disable responsiveness for a fixed height
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            // Display the original value in the tooltip
            return `Value: ${responsevaldata[context.dataIndex]}`;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Accessibility Summary",
        color: "#0b2033",
        font: {
          size: 18,
          family: "Poppins",
        },
      },
    },
    scales: {
      y: {
        ticks: {
          suggestedMin: 0,
          stacked: true,
          color: "#0b2033",
          font: {
            size: 14,
            family: "Poppins",
          },
          beginAtZero: true,
        },
      },
      x: {
        ticks: {
          color: "#0b2033",
          font: {
            size: 12,
            family: "Poppins",
          },
          beginAtZero: true,
        },
      },
    },
  }}
/>

          <p></p>
        </div>

        <div className="data_table accessbility_score_table accessbility_report_score_table" style={{ width: "100%" }}>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid

              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                // ".MuiDataGrid-virtualScroller": {
                //   height: "auto !important",
                //   overflowY: "auto",
                // },

                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                },
              }}
              rows={rows}
              columns={columndata}
              getRowHeight={() => 'auto'}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pinnedColumns: { left: [''] },
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              // loading={loading}
              disableColumnSelector
              disableDensitySelector
            />
          </div>
        </div>





        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter"
        >
          <DialogTitle>{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">

                <div>

                  <div className="" style={{ marginBottom: '10px' }}>
                    <div>
                      <span>Completed Date</span>
                      <Select
                        values={[dEngineoption]}
                        options={completedate}
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          OnsetSelectval(values)
                          SetdEngineoption({
                            completed_at:
                              values[0].completed_at,
                            id:
                              values[0].id,
                          });

                        }}
                      />
                    </div>


                  </div>



                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>



                </div>

              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
              Submit
            </button>
            <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Error Message "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              No Data Found
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </div>
  );
}
