import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import axios from "axios";
import "./style.css";
import calendarpng from './Asset/New_Assert/ESO_Icon/Filter.svg'
import pdficon from './Asset/PDF.png'
import csvicon from './Asset/CSV.png'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from "react-modal";
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import fav_icon from './Asset/New_Assert/ESO_ICON.png';
import { red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import Select from "react-dropdown-select";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "./RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Loader from "./CommonLoader";
import { AppContext } from "../../Context/Context";
import { Commonfun } from './Commonfun';
import { timezonechange } from './Commonfun';
import { timezoneval } from './Commonfun';
export default function Schemaanalysis() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const detailurl = '/schema-detail/';
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");

  const [jobDate, setjobDate] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)

  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [fataloutCount, setfataloutCount] = useState();
  const [erroroutCount, seterroroutCount] = useState();
  const [warningoutCount, setwarningoutCount] = useState();
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: ""
  });
  let errors = { ...validation };
  let subtitle;


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };




  const arr = [];
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue[0].value)
    callUserdataApi("", passvalue[0].value);
  }
  useEffect(() => {
    FetchJobDates();
    callUserdataApi();
    LoadAddFormFilterOptions();
    faqdatafun();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetDomainsectiondata(Sections[0].value)
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }
  const columns = [

    {
      field: 'sid',
      headerName: '#',
      description: 'Index of the row',
      minWidth: 70,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: 'link__url',
      headerName: 'URL',
      description: 'Web page URL where the rich snippet is implemented',
      minWidth: 600,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        let firstEightLetters = cardData.row.link__url.substring(0, 50);
        let lastThreeLetters = cardData.row.link__url.slice(-5);

        return (
          <>
            <Tooltip title={cardData?.row?.link__url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a target="_blank" href={cardData?.row?.link__url}>{cardData?.row?.link__url}</a>
              </div>

            </Tooltip>
          </>
        );

      },

    },
    {
      field: 'warning_count',
      headerName: 'Warnings',
      description: 'Warning for missing properties or item types in the rich snippets implemented.',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: 'error_count',
      headerName: 'Errors',
      description: 'Error for missing properties or item type in the rich snippets implemented.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: '',
      headerName: 'View',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.id}` + "/" + `${jobidData}`}>
            <button className="buttonView">
              View Report
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
          </a>
        );
      },
    },


  ];

  const rows = cardData
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;
    if (jobid == "")
      jobid = jobidData;
    closeModal()
    setCardData([])
    setLoading(true);

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if(datavalue?.data?.job_date) 
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))    
        else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))

      if (datavalue?.data) {
        const dataval = datavalue?.data?.table_data;

        // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

       
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setwarningoutCount(datavalue?.data?.warning_count)
        seterroroutCount(datavalue?.data?.error_count)
        setCardData(newData);
        SetAutoheightval(false)
        setjobidData(datavalue?.data?.job_id)
        setSelectval(datavalue?.data?.job_id)
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }

    } catch (err) {
      console.log('err', err)
    }




    setLoading(false)

  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1
    let errors = {};


    if (startDate == "" || endDate == "") {
      errors.dateerror = "This field is required.";
      errs = 2
    }
    else {
      errors.dateerror = "";
      errs = 1
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    var start_date = moment(startDate).format('YYYY-MM-DD')
    var end_date = moment(endDate).format('YYYY-MM-DD')

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/analysis_summary_crawl_dates/?start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });
    if (datavalue?.data?.result.length > 0) {
      const dataval = datavalue?.data.result[0].job_id;
      setjobidData(dataval);
      callUserdataApi()

    }
    else {
      handleClickOpen()
    }

  }


  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }



  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );


  const FetchJobDates = async () => {


    try {

      const completevalues = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=schema&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (completevalues?.data?.jobs.length > 0) {
        console.log("schema", completevalues.data.jobs[0])
        SetdEngineoption(completevalues?.data?.jobs[0])
        setcompletedate(completevalues?.data?.jobs)
      }

    } catch (err) {
      console.log('err', err)
    }





  }




  const FetchList = () => {

    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    var domainsec = "";
    callUserdataApi(selectval[0]?.id, domainsec = "");
  }



  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('schema')
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const pdfdown = async () => {

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidData}&domain_section_id=${Domainsectiondata}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Schemaanalysis", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidData}&domain_section_id=${Domainsectiondata}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Schemaanalysis", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=18`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schema Analysis</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">

        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Schema Analysis</h2>
            {/* <p>as of {jobDate}</p> */}
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
              <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                <QuestionMarkIcon />

              </button>
              {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
              <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
              <img src={csvicon} alt="csv icon" onClick={csvdown} />
            </div>

          </div>
        </div>

        <div className="cnt_hdr_blw_wrap">
          <button className="run_rpt_btn rn_rpt_btn" onClick={() => { SetRunReportPopShow(true) }}>Run Report Now</button>
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
          {SectionOptions?.length > 0 &&
            <div className="listdropdown">
              <div>
                <span className="listdropdown_titl">Domain Section</span>

                <Select
                  className="drop-organicsearch"
                  name="name"
                  isClearable
                  onChange={SetSectionNameHandle}
                  options={SectionOptions}
                  isSearchable="true"
                  placeholder="Home Page"
                />

              </div>
            </div>
          }
        </div>

        <div className="cnt_hdr_content">
          <div className="box_wrap">
            <div>
              <h1></h1>
              <p></p>
            </div>
            <div className="cnt_hdr_content_box_outer">

              <div className="plagiarisam_missing_waring_wrap" style={{ backgroundColor: "rgb(243, 0, 0)" }}>
                <p> <b>{erroroutCount}</b></p>
                <p><b>Error Count</b></p>
              </div>
              <div className="plagiarisam_missing_waring_wrap" style={{ backgroundColor: "rgb(253, 254, 78)" }}>
                <p> <b>{warningoutCount}</b></p>
                <p><b>Warning Count</b></p>
              </div>

            </div>
          </div>
        </div>


        <div className="data_table schema_analysed_table_Data" style={{ width: "100%" }}>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid

              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                // ".MuiDataGrid-virtualScroller": {
                //   height: "auto !important",
                //   overflowY: "auto",
                // },
                "& .MuiDataGrid-cell:first-child": {
                  position: "unset",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                },
              }}
              rows={rows}
              columns={columns}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              // loading={loading}
              disableColumnSelector
              disableDensitySelector
            />
          </div>
        </div>



        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter"
        >
          <DialogTitle>{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">

                <div>

                  <div className="">
                    <div>
                      <span>Completed Date</span>

                      <Select
                        values={[dEngineoption]}
                        options={completedate}
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          setSelectval(values)

                          SetdEngineoption({
                            completed_at:
                              values[0].completed_at,
                            id:
                              values[0].id,
                          });
                        }}
                      // DefaultValues={({"completed_at":
                      // completedate[0].completed_at,
                      //   "id":
                      //   completedate[0].id,})}
                      />
                    </div>


                  </div>

                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>


                </div>

              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="run_rpt_btn rn_rpt_btn" onClick={FetchList} style={{ cursor: 'pointer' }} >
              Submit
            </button>
            <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog>




        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Error Message "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              No Data Found
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>

      </div>

      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />

    </div>
  );
}